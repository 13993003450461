import React from 'react'
import { Fetchable, FetchState } from '../api/Fetchable'
import { Spinner } from '@blueprintjs/core'

type FechtedProps = {
    fetchable: Fetchable<any>
    children: React.ReactElement
}


export default (props: FechtedProps) => {
    if (props.fetchable.state == FetchState.LOADING) {
        return (
            <div style={{padding: 5}}>
                <Spinner size={30}/>
            </div>
        )
    }
    return (props.children)
}