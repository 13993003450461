import React, { useEffect } from "react";
import {
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import { Navbar, Button, Alignment } from '@blueprintjs/core'
import Home from './home/Home'
import Channels from './channels/Channels'
import Topics from './topics/Topics'
import Login from './login/Login'
import { User } from '../types/user'
import { connect } from 'react-redux'
import { StateType } from '../redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { fetchUser, logout } from '../ducks/users/userDuck'
import logo from '../logo.svg'

const style = require('./Main.module.css');

type MainPropsType = {
    user: User | null,
}
type MainDispatchPropsType = {
    checkUser: (username?: string, password?: string) => void
    logout: () => void
}

const mapStateToTopicsProps = (state: StateType): MainPropsType => {
    return {
        user: state.user.user
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch<any, unknown, AnyAction>): MainDispatchPropsType => {
    return {
        checkUser: (username?: string, password?: string) => {
            dispatch(fetchUser(username, password))
        },
        logout: () => {
            dispatch(logout())
        }
    }
}


const Main = (props: MainPropsType & MainDispatchPropsType) => {

    let location = useLocation()
    useEffect(() => {
        props.checkUser()
    }, [location])
    return (
        <div>
            {props.user != null ?
                <div>
                    <Navbar fixedToTop={true}>
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading><img src={logo} height="auto" width="150px" /></Navbar.Heading>
                            <Navbar.Divider />
                            <Link to="/"><Button text="Home" minimal={true} /></Link>
                            <Link to="/topics"><Button text="Topics" minimal={true} /></Link>
                            <Link to="/channels"><Button text="Notification channles" minimal={true} /></Link>
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}>
                            <Button minimal={true} text="Logout" onClick={() => props.logout()} />
                        </Navbar.Group>
                    </Navbar>
                    <Switch>
                        <Route path="/topics">
                            <Topics />
                        </Route>
                        <Route path="/channels">
                            <Channels />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </div>
                :
                <Login />}
        </div>
    )
}

export default connect(
    mapStateToTopicsProps,
    mapDispatchToProps
)(Main)
