import { Action, AnyAction } from "redux";
import { api } from '../../api/Api'
import Subscription from "../../types/subscription";
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import Template from "../../types/template";
import { PayloadAction } from '../../utils'
import { Fetchable, empty, loaded } from '../../api/Fetchable'

export type SubscriptionsStateType = {
    subscriptions: Fetchable<Array<Subscription>>
    templates: Fetchable<Array<Template>>
    selectedSubscription: Subscription | null
    selectedTemplate: Template | null
    subscriptionFormVisible: boolean
}

type FetchSubscriptionsAction = Action<'FETCH_SUBSCRIPTIONS'>
type StoreSubscriptionsAction = PayloadAction<'STORE_SUBSCRIPTIONS', { subscriptions: Array<Subscription> }>

type FechtTemplatesAction = Action<'FETCH_TEMPLATES'>
type StoreTemplatesAction = PayloadAction<'STORE_TEMPLATES', { templates: Array<Template> }>


const storeSubscriptions = (subscriptions: Array<Subscription>): StoreSubscriptionsAction => {
    return {
        type: 'STORE_SUBSCRIPTIONS',
        subscriptions
    }
}

export const fetchSubscriptions = (): ThunkAction<void, SubscriptionsStateType, unknown, StoreSubscriptionsAction> => async (dispatch: ThunkDispatch<SubscriptionsStateType, unknown, StoreSubscriptionsAction | FetchSubscriptionsAction>) => {
    dispatch({ type: 'FETCH_SUBSCRIPTIONS' })
    const subscriptions = await api.getSubscriptions()
    dispatch(storeSubscriptions(subscriptions.data ?? []))
}


const storeTemplates = (templates: Array<Template>): StoreTemplatesAction => {
    return {
        type: 'STORE_TEMPLATES',
        templates
    }
}

export const fetchTemplates = (): ThunkAction<void, SubscriptionsStateType, unknown, StoreTemplatesAction> => async (dispatch: ThunkDispatch<SubscriptionsStateType, unknown, StoreTemplatesAction | FechtTemplatesAction>) => {
    dispatch({ type: 'FETCH_TEMPLATES' })
    const templates = await api.getSubscriptionTemplates()
    dispatch(storeTemplates(templates.data ?? []))
}


type SelectSubscriptionAction = PayloadAction<'SELECT_SUBSCRIPTION', { subscription: Subscription | null }>

export const selectSubscription = (subscription: Subscription | null): SelectSubscriptionAction => {
    return {
        type: 'SELECT_SUBSCRIPTION',
        subscription
    }
}


type SelectTemplateAction = PayloadAction<'SELECT_TEMPLATE', { template: Template | null }>

export const selectTemplate = (template: Template | null): SelectTemplateAction => {
    return {
        type: 'SELECT_TEMPLATE',
        template
    }
}


type OpenSubscriptionFormAction = Action<'OPEN_SUBSCRIPTION_FORM'>

export const openSubscriptionForm = (): OpenSubscriptionFormAction => {
    return {
        type: 'OPEN_SUBSCRIPTION_FORM',
    }
}

type CloseSubscriptionFormAction = Action<'CLOSE_SUBSCRIPTION_FORM'>

export const closeSubscriptionForm = (): CloseSubscriptionFormAction => {
    return {
        type: 'CLOSE_SUBSCRIPTION_FORM',
    }
}


export const saveSubscription = (subscription: Subscription): ThunkAction<void, SubscriptionsStateType, unknown, AnyAction> => async (dispatch: ThunkDispatch<SubscriptionsStateType, unknown, AnyAction>) => {
    await api.saveSubscription(subscription)
    dispatch(closeSubscriptionForm())
    dispatch(fetchSubscriptions())
}

export const deleteSubscription = (subscription: Subscription): ThunkAction<void, SubscriptionsStateType, unknown, AnyAction> => async (dispatch: ThunkDispatch<SubscriptionsStateType, unknown, AnyAction>) => {
    await api.deleteSubscription(subscription)
    dispatch(fetchSubscriptions())
}

export const subscriptionsReducer = (state: SubscriptionsStateType = { subscriptions: empty<Array<Subscription>>(), templates: empty<Array<Template>>(), selectedSubscription: null, selectedTemplate: null, subscriptionFormVisible: false }, action: AnyAction): SubscriptionsStateType => {
    switch (action.type) {
        case 'FETCH_SUBSCRIPTIONS':
            return { ...state, subscriptions: empty<Array<Subscription>>() }
        case 'STORE_SUBSCRIPTIONS':
            return { ...state, subscriptions: loaded(action.subscriptions) }
        case 'FETCH_TEMPLATES':
            return { ...state, templates: empty<Array<Template>>() }
        case 'STORE_TEMPLATES':
            return { ...state, templates: loaded(action.templates) }
        case 'SELECT_SUBSCRIPTION':
            return { ...state, selectedSubscription: action.subscription, subscriptionFormVisible: true }
        case 'SELECT_TEMPLATE':
            return { ...state, selectedTemplate: action.template, subscriptionFormVisible: true }
        case 'OPEN_SUBSCRIPTION_FORM':
            return { ...state, subscriptionFormVisible: true }
        case 'CLOSE_SUBSCRIPTION_FORM':
            return { ...state, subscriptionFormVisible: false, selectedSubscription: null, selectedTemplate: null }
        // FIXME: This is little bit weird, but when we close the dialog, we need to "invalidate" its content.
        case 'SELECT_TOPIC':
            if (action.topic == null)
                return { ...state, subscriptions: empty<Array<Subscription>>() }
            return { ...state }
        default:
            return { ...state }
    }
}