import React from 'react'

const styles = require('./Home.module.css')

export default (props: {}) => (
    <div className={`bp3-running-text ${styles.Welcome}`}>
        <div className={styles.Content}>
            <h1>Welcome</h1>
            <h2>Application map</h2>
            <h3>Topics</h3>
            <p>On this page you can find data topics to which you can subscribe. If there is a change in any of the subscribed topics, you will be notified.</p>
            <h4>What is a "Topic"</h4>
            <p>Topic is an entity that represents some kind of data. Those data are updated by the aplication and an user can subscribe to these changes.</p>
            <h3>Notification channels</h3>
            <p>On this page you can setup your notification channels which you can then use to subscribe to a topic.</p>
            <h2>I'm here for the first time!</h2>
            <p>In short, first step is to go to the <strong>Notification channeks</strong> page and setup your first notification channel. Once you have your notification channel ready you can go
        to the <strong>Topics</strong> page and search for topics you are interested in.</p>
        </div>
    </div>
)