import React from 'react'
import { Dialog, Button, NonIdealState, Icon } from '@blueprintjs/core'
import Subscription from '../../types/subscription'
import ChannelItem from './ChannelItem'
import ChannelForm from './ChannelForm'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { StateType } from '../../redux'
import { fetchSubscriptions, fetchTemplates, selectSubscription, openSubscriptionForm, closeSubscriptionForm, deleteSubscription } from '../../ducks/subscriptions/subscriptionsDuck'
import { Fetchable } from '../../api/Fetchable'
import FetchedContent from '../Fetched'

const style = require('./Channels.module.css');

type ChannelsProps = {
    channels: Fetchable<Array<Subscription>>
    subscriptionDialogVisible: boolean
}

type ChannelDispatchProps = {
    onMount: () => void
    onSubscriptionSelected: (subscription: Subscription | null) => void
    onNewSubscription: () => void
    onSubscriptionFormClose: () => void
    onChannelDelete: (subscription: Subscription) => void
}

const mapStateToChannelsProps = (state: StateType): ChannelsProps => {
    return {
        channels: state.channels.subscriptions,
        subscriptionDialogVisible: state.channels.subscriptionFormVisible
    }
}

const mapDispatchToChannelsProps = (dispatch: ThunkDispatch<any, unknown, AnyAction>): ChannelDispatchProps => {
    return {
        onMount: () => {
            dispatch(fetchSubscriptions())
            dispatch(fetchTemplates())
        },
        onSubscriptionSelected: (subscription: Subscription | null) => {
            dispatch(selectSubscription(subscription))
        },
        onNewSubscription: () => {
            dispatch(openSubscriptionForm())
        },
        onSubscriptionFormClose: () => {
            dispatch(closeSubscriptionForm())
        },
        onChannelDelete: (subscription: Subscription) => {
            dispatch(deleteSubscription(subscription))
        }
    }
}

class ChannelsPage extends React.Component<ChannelsProps & ChannelDispatchProps> {

    constructor(props: ChannelsProps & ChannelDispatchProps) {
        super(props)
    }

    componentDidMount() {
        this.props.onMount();
    }

    render() {
        return (
            <div className={style.ChannelsPage}>
                <div className={style.ChannelList}>
                    <Button icon={"plus"} minimal={true} onClick={() => this.props.onNewSubscription()} className={style.NewChannelButton} />
                    <FetchedContent fetchable={this.props.channels}>
                        <div>
                            {(this.props.channels.data?.length ?? 0) > 0 ?
                                this.props.channels.data?.map((channel: Subscription) =>
                                    <ChannelItem key={channel.id as number /* todo fix this, in this case the id wont be null*/}
                                        channel={channel}
                                        onChannelDelete={() => this.props.onChannelDelete(channel)}
                                        onClick={() => this.props.onSubscriptionSelected(channel)}
                                        className={style.ChannelListItem}
                                        headerClassName={style.ChanneListItemHeader} />)
                                :
                                <NonIdealState
                                    title={<div>You don't have any channels created. You can use the <Icon style={{ padding: 3 }} icon={'plus'} /> button to create one.</div>}
                                />
                            }
                        </div>
                    </FetchedContent>
                </div>
                <Dialog isOpen={this.props.subscriptionDialogVisible}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    usePortal={true}
                    onClose={() => {
                        this.props.onSubscriptionFormClose()
                    }}>
                    <ChannelForm />
                </Dialog>
            </div>
        )
    }

}

export default connect(
    mapStateToChannelsProps,
    mapDispatchToChannelsProps
)(ChannelsPage)