import React, { useEffect } from 'react'
import { Button, FormGroup, InputGroup, Icon } from '@blueprintjs/core'
import logo from '../../logo.svg'
import { LoginOption } from '../../types/loginoption';
import { User } from '../../types/user';
import { connect } from 'react-redux'
import { StateType } from '../../redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { fetchLoginOptions, login, missingLoginInput } from '../../ducks/users/userDuck'
import { Fetchable } from '../../api/Fetchable'
import FetchedContent from '../Fetched'

const style = require('./Login.module.css');

type LoginPropsType = {
    user: User | null
    loginOptions: Fetchable<Array<LoginOption>>
    error?: string
}

type LoginDispatchPropsType = {
    onSubmit: (username: string, password: string) => void
    onMissingInput: () => void
    fetchLoginOptions: () => void
}

type Credentials = {
    username: string
    password: string
}

const getCredentials = (): Credentials => {
    const username = (document.getElementById('username') as HTMLFormElement).value
    const password = (document.getElementById('password') as HTMLFormElement).value
    return { username, password }
}

const mapStateToTopicsProps = (state: StateType): LoginPropsType => {
    return {
        user: state.user.user,
        loginOptions: state.user.loginOptions,
        error: state.user.error
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch<any, unknown, AnyAction>): LoginDispatchPropsType => {
    return {
        onSubmit: (username?: string, password?: string) => {
            dispatch(login(username, password))
        },
        fetchLoginOptions: () => {
            dispatch(fetchLoginOptions())
        },
        onMissingInput: () => {
            dispatch(missingLoginInput())
        }
    }
}


const Login = (props: LoginPropsType & LoginDispatchPropsType) => {
    useEffect(() => {
        props.fetchLoginOptions()
    }, [props.user])

    const loginAction = () => {
        const credentials = getCredentials()
        if (!credentials.username || !credentials.password) {
            props.onMissingInput()
        } else {
            props.onSubmit(credentials.username, credentials.password);
        }
    }

    return (
        <div className={style.LoginForm}>
            <span>
                <img src={logo} />
            </span>
            <FormGroup
                label="Username"
                labelFor="username"
            >
                <InputGroup id="username" placeholder="Username" required={true} onKeyPress={(e) => {
                    if (e.key == 'Enter')
                        loginAction()
                }} />
            </FormGroup>
            <FormGroup
                label="Password"
                labelFor="password"
            >
                <InputGroup id="password" type="password" required={true} onKeyPress={(e) => {
                    if (e.key == 'Enter')
                        loginAction()
                }} />
            </FormGroup>
            {
                props.error ? <span className={style.ErrorMessage}>{props.error}</span> : <span></span>
            }
            <Button text="Log in"
                outlined={true}
                onClick={loginAction} />
            <div className={style.SSOOptions}>
                <FetchedContent fetchable={props.loginOptions}>
                    <div>
                        {props.loginOptions.data?.map((option: LoginOption) => (
                            <Button
                                outlined={true}
                                key={option.registrationCode}
                                onClick={() => window.location.href = option.url}
                                text={`Log in using ${option.label}`} />
                        ))}
                    </div>
                </FetchedContent>
            </div>
        </div>)
}


export default connect(
    mapStateToTopicsProps,
    mapDispatchToProps
)(Login)
