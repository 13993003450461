import { User } from '../../types/user'
import { api } from '../../api/Api'
import { Fetchable, empty, loaded } from '../../api/Fetchable'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { StateType } from '../../redux'
import { PayloadAction } from '../../utils'
import { LoginOption } from '../../types/loginoption'

export type UserStateType = {
    user: User | null
    loginOptions: Fetchable<Array<LoginOption>>
    error?: string
}

export type StoreUserAction = PayloadAction<'STORE_USER', { user: User | null }>

export const storeUser = (user: User | null): StoreUserAction => {
    return {
        type: 'STORE_USER',
        user
    }
}

export const fetchUser = (username?: string, password?: string): ThunkAction<void, UserStateType, unknown, StoreUserAction> => async (dispatch: ThunkDispatch<UserStateType, unknown, StoreUserAction>) => {
    const user = await api.getUser(username, password)
    dispatch(storeUser(user.data as User))
}


export const login = (username?: string, password?: string): ThunkAction<void, UserStateType, unknown, StoreUserAction> => async (dispatch: ThunkDispatch<UserStateType, unknown, StoreUserAction | ErrorMessageAction>) => {
    const user = await api.getUser(username, password)
    switch (user.status) {
        case 401:
            dispatch(loginFailed401())
            return
        case 500:
            dispatch(loginFailed500())
            return
    }

    dispatch(storeUser(user.data as User))
}


type ErrorMessageAction = PayloadAction<'ERROR_MESSAGE', { message: string }>
const loginFailed401 = (): ErrorMessageAction => {
    return { type: 'ERROR_MESSAGE', message: 'Wrong username or password' }
}
const loginFailed500 = (): ErrorMessageAction => {
    return { type: 'ERROR_MESSAGE', message: 'Failed to login, please try it later' }
}



export const missingLoginInput = (): ErrorMessageAction => {
    return { type: 'ERROR_MESSAGE', message: 'Please fill both username and password' }
}

export const logout = (): ThunkAction<void, UserStateType, unknown, StoreUserAction> => async (dispatch: ThunkDispatch<UserStateType, unknown, StoreUserAction>) => {
    await api.logout()
    dispatch(storeUser(null))
}



type StoreLoginOptionsAction = PayloadAction<'STORE_LOGIN_OPTIONS', { options: Array<LoginOption> }>

export const storeLoginOptions = (options: Array<LoginOption>): StoreLoginOptionsAction => {
    return {
        type: 'STORE_LOGIN_OPTIONS',
        options
    }
}


type FetchLoginOptionsAction = Action<'FETCH_LOGIN_OPTIONS'>
export const fetchLoginOptions = (): ThunkAction<void, UserStateType, unknown, StoreLoginOptionsAction> => async (dispatch: ThunkDispatch<UserStateType, unknown, StoreLoginOptionsAction | FetchLoginOptionsAction>) => {
    dispatch({ type: 'FETCH_LOGIN_OPTIONS' })
    const loginOptions = await api.getLoginOptions()
    dispatch(storeLoginOptions(loginOptions.data ?? []))
}


export const userReducer = (state: UserStateType = { user: null, loginOptions: empty<Array<LoginOption>>() }, action: AnyAction): UserStateType => {
    console.log(JSON.stringify(action))
    switch (action.type) {
        case 'ERROR_MESSAGE':
            return { ...state, error: action.message }
        case 'STORE_USER':
            return { ...state, user: action.user, error: undefined }
        case 'FETCH_LOGIN_OPTIONS':
            return { ...state, loginOptions: empty<Array<LoginOption>>() }
        case 'STORE_LOGIN_OPTIONS':
            return { ...state, loginOptions: loaded(action.options), }
        default:
            return { ...state }
    }
}
