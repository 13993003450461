import React from 'react'
import Topic from '../../../types/topic'
import Subscription from '../../../types/subscription'
import { Dialog, HTMLTable, Button, Popover, HTMLSelect } from '@blueprintjs/core'
import TopicSubscription from '../../../types/topicSubscription'
import { connect } from 'react-redux'
import { StateType } from '../../../redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { selectTopic, subscribeToTopic, unsubscribeTopic } from '../../../ducks/topics/topicsDuck'
import { fetchSubscriptions } from '../../../ducks/subscriptions/subscriptionsDuck'
import { Fetchable } from '../../../api/Fetchable'
import FetchedContent from '../../Fetched'

const style = require('./TopicDetail.module.css')

type TopicDetailPropsType = {
    topic: Topic | null
    channels: Fetchable<Array<Subscription>>

}
type TopicDetailDispatchType = {
    onClose: () => void
    onOpen: () => void
    onChannelAdd: (channelId: number, topic: Topic) => void
    onChannelRemove: (channelId: number, topic: Topic) => void
}

type TopicDetailProps = TopicDetailPropsType & TopicDetailDispatchType

const mapStateToTopicsProps = (state: StateType): TopicDetailPropsType => {
    return {
        topic: state.topics.selectedTopic,
        channels: state.channels.subscriptions,
    }
}

const mapDispatchToTopicsProps = (dispatch: ThunkDispatch<any, unknown, AnyAction>): TopicDetailDispatchType => {
    return {
        onOpen: () => {
            dispatch(fetchSubscriptions())
        },
        onClose: () => {
            dispatch(selectTopic(null))
        },
        onChannelAdd: (channelId: number, topic: Topic) => {
            dispatch(subscribeToTopic(channelId, topic))
        },
        onChannelRemove: (channelId: number, topic: Topic) => {
            dispatch(unsubscribeTopic(channelId, topic))
        },
    }
}


const getChannelSelectValue = (): number => {
    return parseInt((document.getElementById("channel-select") as HTMLSelectElement).value)
}

const TopicDetail = (props: TopicDetailProps) => (
    <Dialog isOpen={props.topic != null}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        usePortal={true}
        onClose={props.onClose}
        onOpened={props.onOpen}
        title={props.topic?.name}
        icon={"info-sign"}>
        <div className={style.Detail}>
            <h3>Description</h3>
            <p>
                {props.topic?.description ?? ""}
            </p>
            <FetchedContent fetchable={props.channels}>
                <HTMLTable className={style.Channels}>
                    <thead>
                        <tr>
                            <th>Channels</th>
                            <th>
                                {props.channels.data?.length ?? 0 > 0 ?
                                    <Popover>
                                        <Button icon={"plus"} minimal={true} />
                                        <div>
                                            <HTMLSelect id="channel-select">
                                                {props.channels.data?.filter((channel: Subscription) => props.topic?.subscriptions.find((subscription: TopicSubscription) => subscription.subscriptionId == channel.id) == undefined).map((channel: Subscription) => (
                                                    <option key={channel.id as number} value={channel.id as number}>{channel.name}</option>
                                                ))}
                                            </HTMLSelect>

                                            <Button text="Add" onClick={() => props.onChannelAdd(getChannelSelectValue(), props.topic as Topic)} />

                                        </div>
                                    </Popover> : <span></span>
                                }</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.channels.data?.filter((channel: Subscription) => props.topic?.subscriptions.find((subsription: TopicSubscription) => subsription.subscriptionId == channel.id))
                                .map((channel: Subscription) =>
                                    <tr key={channel.id as number}>
                                        <td>{channel.name}</td>
                                        <td><Button icon={"trash"} minimal={true} onClick={() => props.onChannelRemove(channel.id as number, props.topic as Topic)} /></td>
                                    </tr>
                                )
                        }
                    </tbody>
                </HTMLTable>
            </FetchedContent>
        </div>
    </Dialog>
)
export default connect(
    mapStateToTopicsProps,
    mapDispatchToTopicsProps
)(TopicDetail)
