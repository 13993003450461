import TopicGroup from "../types/topicGroup";
import Topic from "../types/topic";
import { User } from "../types/user";
import axios, { AxiosResponse, AxiosInstance } from 'axios'
import Subscription from "../types/subscription";
import Template from "../types/template";
import { LoginOption } from "../types/loginoption";

export type ApiResponse<T> = {
    data?: T
    status: number
}

class Api {

    private endpoint: string
    private restEndpoint: string
    private apiVersion: string
    private authEndpoint: string
    private parentType = 199852
    private axiosInstance: AxiosInstance

    constructor(endpoint: string, apiVersion: string, authEndpoint: string) {
        this.endpoint = endpoint
        this.apiVersion = apiVersion
        this.authEndpoint = authEndpoint
        this.restEndpoint = `${endpoint}/api/${apiVersion}`
        this.axiosInstance = axios.create({
            validateStatus: (status) => status >= 200 && status <= 500
        })
    }

    async getTopicGroups(): Promise<ApiResponse<Array<TopicGroup>>> {
        const groups = await this.axiosInstance.get(`${this.restEndpoint}/topicgroups`, { validateStatus: (status) => true })
        return { data: groups.data as Array<TopicGroup>, status: groups.status }
    }

    async getTopicsForGroup(group: number, withSubscriptions: boolean = true): Promise<ApiResponse<Array<Topic>>> {
        const topics = await this.axiosInstance.get(`${this.restEndpoint}/topicgroups/${group}/topics${withSubscriptions ? '?subscriptions=true' : ''}`)
        return { data: topics.data as Array<Topic>, status: topics.status }
    }

    async getSubscribedTopics(): Promise<ApiResponse<Array<Topic>>> {
        const topics = await this.axiosInstance.get(`${this.restEndpoint}/topics?subscriptions=true&onlySubscribed=true`)
        return { data: topics.data, status: topics.status }
    }

    async getSubscriptions(): Promise<ApiResponse<Array<Subscription>>> {
        const subscriptions = await this.axiosInstance.get(`${this.restEndpoint}/subscriptions`)
        return { data: subscriptions.data as Array<Subscription>, status: subscriptions.status }
    }

    async subscribeToTopic(channelId: number, topicId: number): Promise<ApiResponse<void>> {
        const response = await this.axiosInstance.post(`${this.restEndpoint}/subscriptions/${channelId}/topicsubscriptions`, {
            topic: topicId,
            refreshPeriod: 5
        })
        return { status: response.status }
    }

    async unsubscribeTopic(channelId: number, topicId: number): Promise<ApiResponse<void>> {
        const response = await this.axiosInstance.delete(`${this.restEndpoint}/subscriptions/${channelId}/topicsubscriptions/${topicId}`)
        return { status: response.status }
    }

    async getSubscriptionTemplates(): Promise<ApiResponse<Array<Template>>> {
        const response = await this.axiosInstance.get(`${this.restEndpoint}/templates?type=${this.parentType}&onlyRequiredAttributes=true`)
        return { data: response.data as Array<Template>, status: response.status }
    }

    async saveSubscription(subscription: Subscription): Promise<ApiResponse<void>> {
        let response: AxiosResponse<any>
        if (subscription.id == null) {
            response = await this.axiosInstance.post(`${this.restEndpoint}/subscriptions`, subscription)
        }
        else {
            response = await this.axiosInstance.put(`${this.restEndpoint}/subscriptions/${subscription.id}`, subscription)
        }
        return { status: response.status }
    }

    async deleteSubscription(subscription: Subscription): Promise<ApiResponse<void>> {
        const response = await this.axiosInstance.delete(`${this.restEndpoint}/subscriptions/${subscription.id}`)
        return { status: response.status }
    }

    async getUser(username?: string, password?: string): Promise<ApiResponse<User | null>> {
        let params = {}
        if (username !== undefined && password !== undefined) {
            params = {
                auth: {
                    username,
                    password
                }
            }
        }
        const response = await this.axiosInstance.get(`${this.restEndpoint}/users/me`, params)
        const user = response.status == 401 ? null : response.data
        return { data: user, status: response.status }
    }

    async getLoginOptions(): Promise<ApiResponse<Array<LoginOption>>> {
        const response = await this.axiosInstance.get(`${this.endpoint}/loginOptions`)
        const options = response.data as Array<LoginOption>
        options.forEach((option: LoginOption) => option.url = `${this.endpoint}/${this.authEndpoint}/${option.registrationCode}`)
        return { data: options, status: response.status }
    }

    async logout(): Promise<ApiResponse<void>> {
        const response = await this.axiosInstance.get(`${this.endpoint}/logout`)
        return { status: response.status }
    }
}

export const api = new Api('be', 'v1', '/oauth2/authorization')