import React, { CSSProperties } from 'react'
import Subscription from '../../types/subscription'
import { Card, Elevation, HTMLTable, Button } from '@blueprintjs/core'

type ChannelItemProps = {
    className: string
    headerClassName: string
    channel: Subscription
    onClick: () => void
    onChannelDelete: (channel: Subscription) => void
}

const headerLabelStyle: CSSProperties = {
    flexGrow: 1
}

export default (props: ChannelItemProps) => (
    <Card interactive={false} elevation={Elevation.TWO} onClick={props.onClick} className={props.className}>
        <div className={props.headerClassName}>
            <h5 style={headerLabelStyle}>{props.channel.name}</h5>
            <Button minimal={true} icon={"trash"} onClick={(e: React.MouseEvent) => { props.onChannelDelete(props.channel); e.stopPropagation() }} />
        </div>
        <HTMLTable>
            <tbody>
                {Array.from(Object.entries(props.channel.attributes)).map(([name, value], index) => {
                    return (<tr key={index}>
                        <td className={'property-name'}>{name}</td>
                        <td>{value as string}</td>
                    </tr>)
                })}
            </tbody>
        </HTMLTable>
    </Card>
) 