export enum FetchState {
    LOADING,
    LOADED,
    ERROR,
}

export type Fetchable<T> = {
    state: FetchState,
    data?: T
}

export const empty = <T>(): Fetchable<T> => ({ state: FetchState.LOADING })
export const loaded = <T>(data: T): Fetchable<T> => ({ state: FetchState.LOADED, data })