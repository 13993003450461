import React from 'react'
import { Suggest, IItemRendererProps, ItemPredicate } from '@blueprintjs/select'
import { MenuItem, FormGroup } from '@blueprintjs/core'
import TopicList from './topicsList/TopicList'
import Topic from '../../types/topic'
import TopicsGroup from '../../types/topicGroup'
import TopicDetail from './topicsList/topicDetail'
import { connect } from 'react-redux'
import { StateType } from '../../redux'
import { fetchGroups, fetchTopicsForGroup, selectTopic, fetchSubscribedTopics } from '../../ducks/topics/topicsDuck'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Fetchable } from '../../api/Fetchable'
import FetchedContent from '../Fetched'

const style = require('./Topics.module.css');

const TopicGroupSuggest = Suggest.ofType<TopicsGroup>()

const renderer = (item: TopicsGroup, itemProps: IItemRendererProps): JSX.Element | null => {
    if (!itemProps.modifiers.matchesPredicate) {
        return null;
    }

    return (
        <MenuItem text={item.name} key={item.id} onClick={itemProps.handleClick} />
    );
}

const groupsFilter: ItemPredicate<TopicsGroup> = (query, group, _index, exactMatch) => {
    const normalizedTitle = group.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return group.name.indexOf(normalizedQuery) >= 0;
    }
};


type TopicsPageProps = {
    topics: Fetchable<Array<Topic>>
    groups: Fetchable<Array<TopicsGroup>>
    selectedGroup: TopicsGroup | null
}

type TopicsPageDispatchProps = {
    onMount: () => void
    fetchSubscribedTopics: () => void
    onGroupSelected: (group: number) => void
    onTopicSelected: (topic: number | null) => void
}

const mapStateToTopicsProps = (state: StateType): TopicsPageProps => {
    return {
        topics: state.topics.topics,
        groups: state.topics.groups,
        selectedGroup: state.topics.selectedGroup
    }
}

const mapDispatchToTopicsProps = (dispatch: ThunkDispatch<any, unknown, AnyAction>): TopicsPageDispatchProps => {
    return {
        onMount: () => {
            dispatch(fetchGroups())
        },
        fetchSubscribedTopics: () => {
            dispatch(fetchSubscribedTopics())
        },
        onGroupSelected: (group: number) => {
            dispatch(fetchTopicsForGroup(group))
        },
        onTopicSelected: (topic: number | null) => {
            dispatch(selectTopic(topic))
        }
    }
}

class TopicsPage extends React.Component<TopicsPageProps & TopicsPageDispatchProps> {

    constructor(props: TopicsPageProps & TopicsPageDispatchProps) {
        super(props)
    }

    componentDidMount() {
        this.props.onMount()
        this.props.fetchSubscribedTopics()

    }

    render() {
        return (
            <div className={style.TopicsPage}>
                <FormGroup
                    helperText="When there is no group selected, the list shows topics you are subscribed to."
                    label="Topic group"
                >
                    <TopicGroupSuggest
                        items={this.props.groups.data ?? []}
                        itemPredicate={groupsFilter}
                        itemRenderer={renderer} onItemSelect={(group: TopicsGroup) => this.props.onGroupSelected(group.id)}
                        inputValueRenderer={(group: TopicsGroup) => group.name}
                        popoverProps={{ minimal: true }}
                        resetOnClose={true}
                        className={style.GroupSearch}
                        noResults={
                            <FetchedContent fetchable={this.props.groups}>
                                <MenuItem disabled={true} text="No results." />
                            </FetchedContent>
                        }
                        fill={true} />
                </FormGroup>
                <FetchedContent fetchable={this.props.topics}>
                    <TopicList topics={this.props.topics.data ?? []} onTopicSelected={this.props.onTopicSelected} className={style.TopicList} itemClassName={style.TopicListItem} />
                </FetchedContent>
                <TopicDetail />
            </div>
        )
    }
}

export default connect(
    mapStateToTopicsProps,
    mapDispatchToTopicsProps
)(TopicsPage)
