import React from 'react'
import Topic from '../../../types/topic'
import { Switch, Card, Elevation } from '@blueprintjs/core'

type TopicItemProps = {
    className: string
    topic: Topic
    onSelected: (topic: number) => void
}

export default (props: TopicItemProps) => (
    <Card interactive={false} elevation={Elevation.TWO} onClick={(_) => props.onSelected(props.topic.id)} className={props.className}>
        <h4>{props.topic.name}</h4>
        <p>
            {props.topic.description ?? ""}
        </p>
        <Switch checked={props.topic.subscriptions.length > 0} label="" disabled={false} readOnly={true}/>
    </Card>
) 