import React from 'react'
import Template from '../../types/template'
import { Attribute, AttributeType } from '../../types/attribute'
import Subscription from '../../types/subscription'
import { FormGroup, InputGroup, HTMLSelect, Button, EditableText, Intent } from '@blueprintjs/core'
import { StateType } from '../../redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { selectTemplate, saveSubscription } from '../../ducks/subscriptions/subscriptionsDuck'
import { Fetchable } from '../../api/Fetchable'
import FetchedContent from '../Fetched'

const style = require('./ChannelForm.module.css');

type ChannelFormPropsType = {
    templates: Fetchable<Array<Template>>
    selectedTemplate: Template | null
    subscription: Subscription | null

}
type ChannelFormDispatchType = {
    onTemplateSelected: (template: Template | null) => void
    saveChannel: (Subscription: Subscription) => void
}
type ChannelFormProps = ChannelFormPropsType & ChannelFormDispatchType

const mapStateToProps = (state: StateType): ChannelFormPropsType => {
    return {
        templates: state.channels.templates,
        selectedTemplate: state.channels.selectedTemplate ?? (getTemplateById(state.channels.selectedSubscription?.templateId as number, state.channels.templates.data ?? []) ?? state.channels.templates.data?.[0]),
        subscription: state.channels.selectedSubscription
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, unknown, AnyAction>): ChannelFormDispatchType => {
    return {
        onTemplateSelected: (template: Template | null) => {
            dispatch(selectTemplate(template))
        },
        saveChannel: (subscription: Subscription) => {
            dispatch(saveSubscription(subscription))
        }
    }
}


const getTemplateById = (id: number, templates: Array<Template>): Template => {
    return templates.find((template: Template) => template.id == id) as Template
}

const getTemplateSelectValue = (templates: Array<Template>): Template | null => {
    return getTemplateById(parseInt((document.getElementById("template-select") as HTMLSelectElement).value) ?? -1, templates)

}

type ChannelFormStateType = {
    channelName: string | undefined
    channelNameEditorText: string
    values: Map<string, string>
}

class ChannelForm extends React.Component<ChannelFormProps, ChannelFormStateType> {

    constructor(props: ChannelFormProps) {
        super(props)
        this.state = { channelNameEditorText: props.subscription?.name ?? '', channelName: props.subscription?.name, values: this.getStateFromProps() }

    }

    private getStateFromProps(): Map<string, string> {
        const values = new Map<string, string>()
        this.props.selectedTemplate?.attributes.forEach((attribute: Attribute) => {
            values.set(attribute.name, this.props.subscription?.attributes[attribute.name])
        })
        return values

    }

    componentDidUpdate(prevProps: ChannelFormPropsType) {
        if (this.props.selectedTemplate?.id !== prevProps.selectedTemplate?.id ||
            this.props.selectedTemplate?.id !== prevProps.selectedTemplate?.id) {
            this.setState({
                values: this.getStateFromProps()
            })
        }
    }

    updateAttributeValue(attributeName: string, value: string) {
        const values = this.state.values
        values.set(attributeName, value)
        this.setState({ values })
    }

    updateName(channelName: string) {
        if (this) // wtf??
            this.setState({ channelName })
    }

    saveChannel() {
        let name = this.state.channelNameEditorText
        if(!name) {
            name = `${this.props.selectedTemplate?.templateName} ${new Date().toISOString()}`
        }
        const attributes = {} as any// todo
        Array.from(this.state.values.entries()).forEach(([name, value]) => {
            attributes[name] = value
        })
        const subscription: Subscription = {
            id: this.props.subscription?.id ?? null,
            name: name as string,
            templateId: this.props.selectedTemplate?.id as number, // todo
            attributes
        }
        this.props.saveChannel(subscription)
    }

    render() {
        return (
            <div className={style.ChannelForm}>
                <div className={style.ChannelFormHeader}>
                    <EditableText
                        className={style.ChannelNameLabel}
                        confirmOnEnterKey={true}
                        value={this.state.channelNameEditorText}
                        onConfirm={this.updateName}
                        intent={this.state.channelNameEditorText != null && this.state.channelNameEditorText.length > 0 ? Intent.SUCCESS : Intent.NONE}
                        onCancel={(lastVal: string) => this.setState({ channelNameEditorText: lastVal })}
                        onChange={(newVal: string) => this.setState({ channelNameEditorText: newVal })}
                        placeholder={"Channel name"} />
                </div>
                {this.props.subscription == null ?
                    <FetchedContent fetchable={this.props.templates}>
                        <HTMLSelect id="template-select"
                            onChange={() => this.props.onTemplateSelected(getTemplateSelectValue(this.props.templates.data ?? []))}
                            defaultValue={this.props.templates.data?.[0].id}
                            minimal={true}>
                            {this.props.templates.data?.map((template: Template) => (
                                <option key={template.id} value={template.id}>{template.templateName}</option>
                            ))}
                        </HTMLSelect>
                    </FetchedContent>
                    :
                    <span className={style.ChannelTemplateName}>{getTemplateById(this.props.subscription.templateId as number, this.props.templates.data ?? [])?.templateName}</span>}

                <div className={style.ChannelProps}>
                    {this.props.selectedTemplate?.attributes.map((attribute: Attribute, index: number) => (
                        <FormGroup key={index}
                            labelFor={`attr-${attribute.name}`}
                            label={attribute.name + ': '}
                            inline={true}>
                            <InputGroup id={`attr-${attribute.name}`}
                                small={true}
                                value={this.state.values.get(attribute.name)}
                                onChange={(e: React.FormEvent<HTMLElement>) => this.updateAttributeValue(attribute.name, (e.target as HTMLFormElement).value)} />
                        </FormGroup>
                    ))}
                </div>

                <Button text={"Save"} onClick={() => this.saveChannel()} outlined={true} />
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChannelForm)