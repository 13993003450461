import React from 'react'
import Topic from '../../../types/topic'
import TopicItem from './TopicItem'
import { NonIdealState } from '@blueprintjs/core'

type TopicListProps = {
    topics: Array<Topic>
    itemClassName: string
    className: string
    onTopicSelected: (topics: number) => void
}


export default (props: TopicListProps) => (
    <div className={props.className}>
        {props.topics.length > 0 ?
            props.topics.map(topic => (
                <TopicItem key={topic.id} topic={topic} onSelected={props.onTopicSelected} className={props.itemClassName} />
            )) : <NonIdealState
                icon={'search'}
                title="No topics in the selected group"
            />
        }
    </div>
)