import { combineReducers } from 'redux'
import { Reducer } from 'react';
import { topicsReducer, TopicsStateType } from './ducks/topics/topicsDuck'
import { subscriptionsReducer, SubscriptionsStateType } from './ducks/subscriptions/subscriptionsDuck'
import { userReducer, UserStateType } from './ducks/users/userDuck'

export type StateType = {
    topics: TopicsStateType
    channels: SubscriptionsStateType,
    user: UserStateType
}

export const rootReducer: Reducer<any, any> = combineReducers({
    topics: topicsReducer,
    channels: subscriptionsReducer,
    user: userReducer
});
