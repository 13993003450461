import TopicGroup from '../../types/topicGroup'
import Topic from '../../types/topic'
import { api } from '../../api/Api'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { StateType } from '../../redux'
import { PayloadAction } from '../../utils'
import { Fetchable, empty, loaded } from '../../api/Fetchable'

export type TopicsStateType = {
    groups: Fetchable<Array<TopicGroup>>
    topics: Fetchable<Array<Topic>>
    selectedGroup: TopicGroup | null
    selectedTopic: Topic | null
}

type StoreGroupsAction = PayloadAction<'STORE_GROUPS', { groups: Array<TopicGroup> }>

const storeGroups = (groups: Array<TopicGroup>): StoreGroupsAction => {
    return {
        type: 'STORE_GROUPS',
        groups: groups
    }
}


type StoreTopicsInGroupAction = PayloadAction<'STORE_TOPICS_IN_GROUP', { topics: Array<Topic>, group: number }>
type FetchTopicsInGroupAction = Action<'FETCH_TOPICS_IN_GROUP'>

const storeTopicsInGroups = (topics: Array<Topic>, group: number): StoreTopicsInGroupAction => {
    return {
        type: 'STORE_TOPICS_IN_GROUP',
        topics,
        group
    }
}


type FetchGroupsAction = Action<'FETCH_GROUPS'>
export const fetchGroups = (): ThunkAction<void, TopicsStateType, unknown, StoreGroupsAction> => async (dispatch: ThunkDispatch<TopicsStateType, unknown, StoreGroupsAction | FetchGroupsAction>) => {
    dispatch({ type: 'FETCH_GROUPS' })
    const groups = await api.getTopicGroups()
    dispatch(storeGroups(groups.data ?? []))
}

export const fetchSubscribedTopics = (): ThunkAction<void, TopicsStateType, unknown, StoreTopicsInGroupAction> => async (dispatch: ThunkDispatch<TopicsStateType, unknown, StoreTopicsInGroupAction | FetchTopicsInGroupAction>) => {
    dispatch({ type: 'FETCH_TOPICS_IN_GROUP' })
    const topics = await api.getSubscribedTopics()
    dispatch(storeTopicsInGroups(topics.data ?? [], -1))
}


export const fetchTopicsForGroup = (group: number): ThunkAction<void, TopicsStateType, unknown, StoreTopicsInGroupAction> => async (dispatch: ThunkDispatch<TopicsStateType, unknown, StoreTopicsInGroupAction | FetchTopicsInGroupAction>) => {
    dispatch({ type: 'FETCH_TOPICS_IN_GROUP' })
    const topics = await (group != -1 ? api.getTopicsForGroup(group) : api.getSubscribedTopics())
    dispatch(storeTopicsInGroups(topics.data ?? [], group))
}

type SelectTopicAction = PayloadAction<'SELECT_TOPIC', { topic: number | null }>

export const selectTopic = (topic: number | null): SelectTopicAction => {
    return {
        type: 'SELECT_TOPIC',
        topic
    }
}

export const subscribeToTopic = (channelId: number, topic: Topic): ThunkAction<void, TopicsStateType, unknown, AnyAction> => async (dispatch: ThunkDispatch<TopicsStateType, unknown, AnyAction>) => {
    const _ = await api.subscribeToTopic(channelId, topic.id)
    dispatch(fetchTopicsForGroup(topic.topicGroupId))
}

export const unsubscribeTopic = (channelId: number, topic: Topic): ThunkAction<void, TopicsStateType, unknown, AnyAction> => async (dispatch: ThunkDispatch<TopicsStateType, unknown, AnyAction>) => {
    const _ = await api.unsubscribeTopic(channelId, topic.id)
    dispatch(fetchTopicsForGroup(topic.topicGroupId))
}



export const topicsReducer = (state: TopicsStateType = { groups: empty<Array<TopicGroup>>(), topics: empty<Array<Topic>>(), selectedTopic: null, selectedGroup: null }, action: AnyAction): TopicsStateType => {

    switch (action.type) {
        case 'FETCH_GROUPS':
            return { ...state, groups: empty<Array<TopicGroup>>() }
        case 'STORE_GROUPS':
            action.groups.unshift({ id: -1, name: 'Subscribed' })
            return { ...state, groups: loaded(action.groups) }
        case 'FETCH_TOPICS_IN_GROUP':
            return { ...state, topics: empty<Array<Topic>>() }
        case 'STORE_TOPICS_IN_GROUP':
            return { ...state, topics: loaded(action.topics), selectedTopic: (action.topics.find((t: Topic) => t.id == state.selectedTopic?.id) ?? null), selectedGroup: state.groups.data?.find((group: TopicGroup) => group.id == action.group) ?? null }
        case 'SELECT_TOPIC':
            return { ...state, selectedTopic: state.topics.data?.find((topic: Topic) => topic.id == action.topic) ?? null }
        case 'SELECT_GROUP':
            return { ...state, }
        default:
            return { ...state }
    }

}
